export default function takeNetList() {
    let takeCurrencies = this.currenciesTake;
    let selected = this?.selected?.take;
    if (!selected) return [];

    let { netName: selectedNetName, originalName: selectedOriginalName, code: selectedCode } = selected;

    let existCurrMetaData = selectedNetName && selectedOriginalName;

    if (!existCurrMetaData) return [];

    let originalCurrencyList = takeCurrencies.filter(c => c.originalName == selectedOriginalName && c.code == selectedCode && c.crypto);

    if (originalCurrencyList.length <= 1) return [];

    originalCurrencyList = originalCurrencyList.map(changeNameCallback);

    return originalCurrencyList;
}

const changeNameCallback = c => {
    let name = c.originalName.charAt(0).toUpperCase() + c.originalName.slice(1);
    return Object.assign({}, c, { name });
};

//
