export default function currenciesGiveFilterNet() {
    let currencies = this.currenciesGive;

    let currenciesNet = {};

    currencies.forEach(c => {
        let { crypto, originalName, netName, code } = c;
        if (crypto && originalName && netName) {
            if (currenciesNet[code.toLowerCase()]) {
                currenciesNet[code.toLowerCase()].push(c);
            } else {
                currenciesNet[code.toLowerCase()] = [c];
            }
        }
    });

    Object.entries(currenciesNet).forEach(([key, value]) => {
        if (value.length <= 1) {
            delete currenciesNet[key];
        }
    });

    let resultCurrencies = [];

    currencies.forEach(c => {
        let isCurrExistNet = false;
        Object.values(currenciesNet).forEach(arr => {
            if (isCurrExistNet) return;
            isCurrExistNet = arr.some(currItem => currItem._id == c._id);
        });
        if (!isCurrExistNet) {
            resultCurrencies.push(c);
        }
    });

    Object.values(currenciesNet).forEach(arr => {
        let firstCurr = arr[0];
        let name = firstCurr.originalName.charAt(0).toUpperCase() + firstCurr.originalName.slice(1);
        let changedCurr = Object.assign({}, firstCurr, { name });
        resultCurrencies.push(changedCurr);
    });

    resultCurrencies.sort(this.sortCurrencies).sort((a, b) => a.sort - b.sort);

    return resultCurrencies;
}
