<template>
    <div v-if="exchanges.length !== 0" class="exchanger">
        <TechBreak v-if="technicalBreak.enabled" :setTechnicalBreak="t => (technicalBreak = t)" :technicalBreak="technicalBreak" />
        <div v-if="exchange && !technicalBreak.enabled" class="grid step-wrap">
            <div :class="{ 'cell cell--4-col cell--2-col-tablet cell--4-col-phone step': true, 'blured-section': blurSection }">
                <div class="step-header">
                    <div class="step-name">
                        <span v-if="!isDesktop">1.</span>
                        {{ $t("exchanger.html.takeTitle") }}
                    </div>
                    <MyInput id="amountTake" label="" v-model.number.trim="amount.take" @input="inputTake" :currency="selected.take" :validation="validations.take" />
                </div>

                <!-- <div>{{ JSON.stringify(takeNetList) }}</div>
                
                
                -->

                <div class="select_net_div" v-if="takeNetList.length && isDesktop">
                    <div class="select_net_div-title">{{ $t("exchanger.html.takeNetFilterName") }}:</div>
                    <div class="select_net_div-list ">
                        <div class="select_net_div-list_item" v-for="(curr, index) in takeNetList" :class="{ active: curr._id === selected.take._id }" :key="index">
                            <div v-on:click="selected.take = curr" :title="curr.netName.toUpperCase()">{{ curr.netName.toUpperCase() }}</div>
                        </div>
                    </div>
                </div>
                <div class="step-sub-header">{{ $t("exchanger.html.takeFilterName") }}:</div>

                <div class="currencyFilter">
                    <div class="" v-for="(item, index) in takeFilterList" :key="index">
                        <div class="filterItem" :class="{ active: item.id === takeActiveFilter }" v-on:click="takeActiveFilter = item.id" :title="item.title">{{ item.name }}</div>
                    </div>
                </div>

                <div class="step-sub-header">
                    {{ $t("exchanger.html.takeSelectCurrency") }}
                </div>
                <!-- <div v-if="!isDesktop" class="currencyDesctop">
                    <MySelect @change-option="c => (selected.take = c)" @currency-selected="c => (selected.take = c)" :options="currenciesTake" :currency="currenciesTake" :selected="selected.take" />
                </div> -->
                <!-- <div class="" v-if="isDesktop">
                    <ItemCurrency v-for="currency in currenciesTakeFilterNet" :key="currency._id" :currency="currency" :nets="takeNetList" :selected="selected.take" @currency-selected="selected.take = currency" />
                </div> -->

                <div v-if="!isDesktop" class="currencyDesctop">
                    <MySelect :nets="takeNetList" @change-option="c => (selected.take = c)" @currency-selected="c => (selected.take = c)" :options="currenciesTakeFilterNet" :currency="currenciesTakeFilterNet" :selected="selected.take" />
                </div>
                <div class="" v-if="isDesktop">
                    <ItemCurrency v-for="currency in currenciesTakeFilterNet" :key="currency._id" :currency="currency" :nets="takeNetList" :selected="selected.take" @currency-selected="selected.take = currency" />
                </div>
                <div class="select_net_div" v-if="takeNetList.length && !isDesktop">
                    <div class="select_net_div-title">{{ $t("exchanger.html.takeNetFilterName") }}:</div>
                    <div class="select_net_div-list ">
                        <div class="select_net_div-list_item" v-for="(curr, index) in takeNetList" :class="{ active: curr._id === selected.take._id }" :key="index">
                            <div v-on:click="selected.take = curr" :title="curr.netName.toUpperCase()">{{ curr.netName.toUpperCase() }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div :class="{ 'cell cell--4-col cell--3-col-tablet cell--4-col-phone step': true, 'blured-section': blurSection }">
                <div class="step-header">
                    <div class="step-name">
                        <span v-if="!isDesktop">2.</span>
                        {{ $t("exchanger.html.giveTitle") }}
                    </div>

                    <MyInput placeholder="0" v-model.number.trim="amount.give" @input="inputGive" :validation="validations.give" :currency="selected.give" />
                </div>

                <div class="select_net_div" v-if="giveNetList.length && isDesktop">
                    <div class="select_net_div-title">{{ $t("exchanger.html.takeNetFilterName") }}:</div>
                    <div class="select_net_div-list ">
                        <div class="select_net_div-list_item" v-for="(curr, index) in giveNetList" :class="{ active: curr._id === selected.give._id }" :key="index">
                            <div v-on:click="selected.give = curr" :title="curr.netName.toUpperCase()">{{ curr.netName.toUpperCase() }}</div>
                        </div>
                    </div>
                </div>

                <div class="step-sub-header">{{ $t("exchanger.html.giveFilterName") }}:</div>

                <div class="currencyFilter">
                    <div class="" v-for="(item, index) in giveFilterList" :key="index">
                        <div class="filterItem" :class="{ active: item.id === giveActiveFilter }" v-on:click="giveActiveFilter = item.id" :title="item.title">{{ item.name }}</div>
                    </div>
                </div>

                <div class="step-sub-header">
                    <div>
                        {{ $t("exchanger.html.giveSelectCurrency") }}
                    </div>

                    <div class="reserves">
                        {{ $t("exchanger.html.reserveTitle") }}
                    </div>
                </div>
                <!-- <div v-if="!isDesktop" class="currencyDesctop">
                    <MySelect @currency-selected="c => (selected.give = c)" @change-option="c => (selected.give = c)" :selected="selected.give" :currency="currenciesGive" :options="currenciesGive" is-reserve />
                </div>
                <div v-if="isDesktop" class="">
                    <ItemCurrency v-for="currency in currenciesGive" :key="currency._id" :currency="currency" :selected="selected.give" @currency-selected="c => (selected.give = c)" is-reserve />
                </div> -->

                <div v-if="!isDesktop" class="currencyDesctop">
                    <MySelect :nets="giveNetList" @currency-selected="c => (selected.give = c)" @change-option="c => (selected.give = c)" :selected="selected.give" :currency="currenciesGiveFilterNet" :options="currenciesGiveFilterNet" is-reserve />
                </div>
                <div v-if="isDesktop" class="">
                    <ItemCurrency :nets="giveNetList" v-for="currency in currenciesGiveFilterNet" :key="currency._id" :currency="currency" :selected="selected.give" @currency-selected="c => (selected.give = c)" is-reserve />
                </div>

                <div class="select_net_div" v-if="giveNetList.length && !isDesktop">
                    <div class="select_net_div-title">{{ $t("exchanger.html.takeNetFilterName") }}:</div>
                    <div class="select_net_div-list ">
                        <div class="select_net_div-list_item" v-for="(curr, index) in giveNetList" :class="{ active: curr._id === selected.give._id }" :key="index">
                            <div v-on:click="selected.give = curr" :title="curr.netName.toUpperCase()">{{ curr.netName.toUpperCase() }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div :class="{ 'cell cell--4-col cell--3-col-tablet cell--4-col-phone step step-details': true, 'blured-section': blurSection }" v-if="exchange">
                <div class="step-header">
                    <div class="step-name">
                        <span v-if="!isDesktop">3.</span>
                        {{ $t("exchanger.html.personalDataTitle") }}
                    </div>

                    <p v-if="!!promocodeDiscount" class="promocode-discount-text">
                        {{ $t("exchanger.html.promocodeRateTirle") }}
                    </p>

                    <div class="currency take">
                        <img alt="Изображение валюты" class="image" :src="image.take" v-if="image.take" />
                        <div class="details">
                            <div class="name">
                                <p>
                                    {{ exchange.currency.take.name }}
                                </p>
                                <SelectNet v-if="!!takeCurrencyMultinet.length" :currencies="takeCurrencyMultinet" :currency="exchange.currency.take" :selectCurrency="c => (selected.take = c)" />
                            </div>

                            <div class="amount">
                                {{ amount.take }}
                                <div class="code">
                                    {{ exchange.currency.take.code }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="icon-exchange">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="-1 4 24 24" fill="none" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-down">
                            <line x1="12" y1="5" x2="12" y2="19"></line>
                            <polyline points="19 12 12 19 5 12"></polyline>
                        </svg>
                    </div>

                    <div class="currency give">
                        <img alt="Изображение валюты" class="image" :src="image.give" v-if="image.give" />
                        <div class="details">
                            <div class="name">
                                <p>{{ exchange.currency.give.name }}</p>

                                <SelectNet v-if="!!giveCurrencyMultinet.length" :currencies="giveCurrencyMultinet" :currency="exchange.currency.give" :selectCurrency="c => (selected.give = c)" />
                            </div>

                            <div class="amount">
                                {{ amount.give }}
                                <div class="code">
                                    {{ exchange.currency.give.code }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="getCity" class="city-section">
                        <p class="city-section_name">
                            {{ $t("exchanger.html.cityTitle") }}
                        </p>
                        <p class="city-section_value">{{ getCity }}</p>
                    </div>

                    <div class="rate-with-commission">
                        <div>
                            <div class="label">{{ $t("exchanger.html.exchangeRateTitle") }}:</div>

                            <div class="rate" v-html="rateHTML"></div>
                        </div>
                    </div>
                    <div v-if="commissionInfo" style="margin: 17px 0px 0px; color: #727272" v-html="commissionInfo"></div>
                    <div v-if="minMaxCommissionInfo" style="margin: 10px 0px 0px; color: #727272" v-html="minMaxCommiwssionInfo"></div>
                    <div v-if="getPromocodeTextInfo" style="margin: 10px 0px 0px; color: #727272" v-html="getPromocodeTextInfo"></div>
                </div>

                <div class="step-sub-header inputs">
                    {{ $t("exchanger.html.enterData") }}
                </div>

                <MyInput v-model.trim="email" label="E-mail" @input="formatEmail" input-type="email" :validation="validations.email" @focus="isNotification = true" v-if="!isAuthenticated" />

                <div v-if="exchange.currency.take.uniqueType === 'qiwi'" class="qiwi-county">
                    <img alt="" v-if="selectedCountry && mobileNumber" class="flag" :src="selectedCountry" :title="selectedCountryAlt" />
                    <img v-if="!mobileNumber || !selectedCountry" class="flag" :title="$t('exchanger.html.regionNotFound')" src="../../assets/qiwi.png" alt="" />
                    <MyInput :value="mobileNumber" @input="mobileNumberHandler" class="qiwi-input" :validation="validations.mobileNumber" :label="$t('exchanger.html.walletQiwi')" />
                </div>

                <div class="">
                    <MyInput v-for="field in fields.take" :key="field._id" v-model="field.value" :label="field.name" :isCard="field.isCard" @input="resetValidateField(field, 'take')" :validation="validationTakeFields[field._id]" @focus="isNotification = true" />
                </div>

                <div v-if="exchange.currency.give.uniqueType === 'qiwi'" class="qiwi-county">
                    <img alt="" v-if="selectedCountry && mobileNumber" class="flag" :src="selectedCountry" :title="selectedCountryAlt" />
                    <img v-if="!mobileNumber || !selectedCountry" class="flag" :title="$t('exchanger.html.regionNotFound')" src="../../assets/qiwi.png" alt="" />
                    <MyInput :value="mobileNumber" @input="mobileNumberHandler" class="qiwi-input" :validation="validations.mobileNumber" :label="$t('exchanger.html.walletQiwi')" />
                </div>

                <div class="">
                    <MyInput v-for="field in fields.give" :key="field._id" v-model="field.value" :label="field.name" :isCard="field.isCard" @input="resetValidateField(field, 'give')" :validation="validationGiveFields[field._id]" @focus="isNotification = true" />
                </div>

                <div class="notification" v-if="isNotification && exchange.currency.take.takeNotification">
                    {{ exchange.currency.take.takeNotification }}
                </div>
                <Notification v-if="isTakeCrypto" />
                <div class="notification" v-if="isNotification && exchange.currency.give.giveNotification">
                    {{ exchange.currency.give.giveNotification }}
                </div>

                <div class="notification" v-if="qiwiNotification">
                    {{ qiwiNotification }}
                </div>

                <div class="notification night" v-if="isNight">
                    {{ $t("main.notificatuin.nightOrder") }}
                </div>

                <GiftPromocode :setPromocode="setPromocode" />

                <Captcha class="captcha" link="captchaOrder" ref="captchaOrder" />

                <Agree :error="errorAgree" v-model="agree" />
                <MyButton class="uppercase" :value="$t('exchanger.html.submit')" :spinner="spinner" @click.native="submitData" />
            </div>
        </div>
        <PreOrder :orderFieldsData="orderFieldsData" :show="showPreorder" :closeModal="closePreOrderHandler" :orderData="orderData" />
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import listsCountry from "@/lists/country.json";

import methods from "./methods/index";
import computeds from "./computeds/index";
import watchers from "./watchers/index";

import MyInput from "../../components/ui/input.vue";
import ItemCurrency from "../../components/item/currency.vue";
import MySelect from "@/components/ui/selectCurrency.vue";
import MyButton from "../../components/ui/button.vue";
import Agree from "../../components/agree.vue";
import Captcha from "../../components/Captcha.vue";
import TechBreak from "./TechBreak.vue";
import GiftPromocode from "./GiftPromocode.vue";
import SelectNet from "./SelectNet.vue";
import PreOrder from "../../components/PreOrder/PreOrder.vue";
import Notification from "./Notification.vue";

import { getIsNight } from "../../utils/time";

export default {
    components: {
        MyInput,
        ItemCurrency,
        MySelect,
        Notification,
        MyButton,
        Agree,
        Captcha,
        TechBreak,
        GiftPromocode,
        SelectNet,
        PreOrder
    },
    data() {
        return {
            exchanges: [],
            takeFilterList: [],
            takeActiveFilter: null,
            giveActiveFilter: null,
            selected: {
                take: null,
                give: null
            },
            exchange: null,
            amount: {
                take: 0,
                give: 0
            },
            fields: {
                take: [],
                give: []
            },

            blurSection: false,
            cityCodes: [],
            isNotification: false,
            validations: {
                take: {
                    isValid: true,
                    text: ""
                },
                give: {
                    isValid: true,
                    text: ""
                },
                email: {
                    isValid: true,
                    text: ""
                },
                promocode: {
                    isValid: true,
                    text: ""
                },
                fields: {
                    take: {},
                    give: {}
                },
                mobileNumber: {
                    isValid: true,
                    text: ""
                }
            },
            validationTakeFields: {},
            validationGiveFields: {},
            email: "",
            agree: false,
            errorAgree: false,
            spinner: false,
            qiwiNotification: "",
            mobileNumber: "",
            selectedCountry: "",
            selectedCountryAlt: "",
            country: listsCountry,
            commissionInfo: "",
            minMaxCommissionInfo: "",
            minExc: null,
            lastInputActive: "take",
            checked: false,
            fieldsStamp: null,
            pairLink: null,
            technicalBreak: {
                enabled: false,
                started: null,
                stopped: null
            },
            promocodeInfo: {
                code: "",
                rub: ""
            },
            showPreorder: false,
            orderData: null,
            orderFieldsData: null
        };
    },
    computed: {
        ...mapGetters(["isDesktop", "isAuthenticated", "user", "referralLink"]),
        ...computeds,
        isTakeCrypto() {
            let exchange = this.exchange;
            let isCrypto = exchange?.currency?.take?.crypto;
            return !!isCrypto;
        },
        isNight: getIsNight
    },
    created() {
        this.eventer.subscribe("clear exchanger fields", this.clearFields);

        let query = this.$route.query;

        if (query.cur_from && query.cur_to) {
            this.pairLink = {
                cur_from: query.cur_from,
                cur_to: query.cur_to
            };
        }
    },
    mounted() {
        this.fetchBreak();
        this.fetchExchangers();
        this.fetchSiteCodes();
        this.sockets.subscribe(`update currency reserve`, c => {
            let exchanges = this.exchanges;
            let index = exchanges.findIndex(item => item.give._id === c._id);

            if (index !== 1) {
                let exchange = this.exchanges[index];
                this.exchanges.splice(index, 1, Object.assign({}, exchange, { give: Object.assign({}, exchange.give, { reserve: c.reserve }) }));
            }
        });
        this.sockets.subscribe("update break e", technicalBreak => {
            this.technicalBreak = technicalBreak;
        });
    },

    watch: {
        agree() {
            this.errorAgree = false;
        },
        ...watchers,

        "exchange._id"() {
            let exchange = this.exchange;
            this.setValidationFields(exchange);
            this.setExchangeFields(exchange);
            this.sockets.subscribe(`update_exchange_${exchange._id}`, e => {
                let updExc = Object.assign({}, e);
                updExc.currency.take.name = this.exchange?.currency?.take?.name;
                updExc.currency.give.name = this.exchange?.currency?.give?.name;

                if (exchange && updExc._id == exchange._id) {
                    this.exchange = updExc;
                }
            });
            if (this.lastInputActive === "take") {
                this.validateInputTake();
            } else if (this.lastInputActive === "give") {
                this.validateInputGive();
            }

            this.rateUpdate();
        },
        "exchange.rate"() {
            if (this.lastInputActive === "take") {
                this.validateInputTake();
            } else if (this.lastInputActive === "give") {
                this.validateInputGive();
            }
            this.rateUpdate();
        },
        getCommissionQiwi() {
            this.rateUpdate();
        },
        getPromocodeGiveAmount() {
            this.rateUpdate();
        },

        promocode() {
            this.promocodeError = "";
        },
        promocodeDiscount() {
            this.rateUpdate();
        },
        "amount.take": function() {
            this.validateInputTake();
        },
        "amount.give": "validateInputGive",
        "selected.take": function() {
            this.changeExchange();
            // this.validateInputTake();
            // this.validateInputGive();
            this.isNotification = false;
        },
        "selected.give": function() {
            this.changeExchange();
            this.lastInputActive = "take";
            this.validateInputGive();
            // this.validateInputTake();
            if (this.selected.give.code !== "BTC") {
                this.minExc = null;
            }
        },
        email() {
            if (this.email) {
                this.validations.email = {
                    isValid: true,
                    text: ""
                };
            }
        }
    },
    methods: {
        closePreOrderHandler() {
            this.showPreorder = !this.showPreorder;
            this.orderData = null;
            this.orderFieldsData = null;
        },
        ...methods,
        setPromocode(code = "", rub = "") {
            this.promocodeInfo = {
                code,
                rub
            };
        },

        ...mapMutations(["SET_LOADING", "SET_SNACK_BAR", "USER_LOGIN", "SET_ORDER_TOKEN", "SET_ORDER_DATA", "SET_CURRENT_ORDER", "LOGOUT_USER"]),
        blurCode() {
            this.checkPromocode();
        }
    },

    unmounted() {
        // console.log("beforeUnmount order");
        // this.eventer.subscribe("clear exchanger fields", this.clearFields);
        // unsub
    },
    destroyed() {
        // console.log("destroyed order");
    }
};
</script>

<style lang="scss" scoped>
$number-of-options: 6;
.amountService {
    display: block;
    margin: 32px 16px;
    cursor: pointer;
    text-decoration: underline;
    color: $grey-lg-text;

    &:hover {
        color: #3597fa;
    }
}

.priceService {
    margin: 32px 16px;
    padding: 8px;
    border: 1px solid $grey-lg-text;
    border-radius: $border-sm;
    color: #565656;
    line-height: 1.2;
}

#form-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

form {
    width: 100%;
    max-width: 500px;

    #form-title {
        margin-top: 0;
        font-weight: 400;
        text-align: center;
    }

    #debt-amount-slider {
        display: flex;
        flex-direction: row;
        align-content: stretch;
        position: relative;
        width: 100%;
        height: 50px;
        user-select: none;

        &::before {
            content: " ";
            position: absolute;
            height: 2px;
            width: 100%;
            width: calc(100% * (#{$number-of-options - 1} / #{$number-of-options}));
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: $grey-lg-text;
        }

        input,
        label {
            box-sizing: border-box;
            flex: 1;
            user-select: none;
            cursor: pointer;
        }

        label {
            display: inline-block;
            position: relative;
            width: 20%;
            height: 100%;
            user-select: none;

            &::before {
                content: attr(data-debt-amount);
                position: absolute;
                left: 50%;
                padding-top: 10px;
                transform: translate(-50%, 45px);
                font-size: 14px;
                letter-spacing: 0.4px;
                font-weight: 400;
                white-space: nowrap;
                opacity: 0.85;
                transition: all 0.15s ease-in-out;
            }

            &::after {
                content: " ";
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 24px;
                height: 24px;
                border: 1px solid $grey-lg-text;
                background: #fff;
                border-radius: 50%;
                pointer-events: none;
                user-select: none;
                z-index: 1;
                cursor: pointer;
                transition: all 0.15s ease-in-out;
            }

            &:hover::after {
                transform: translate(-50%, -50%) scale(1.25);
            }
        }

        input {
            display: none;

            &:checked {
                + label::before {
                    font-weight: 800;
                    opacity: 1;
                }

                + label::after {
                    border-width: 2px;
                    transform: translate(-50%, -50%) scale(0.75);
                }

                ~ #debt-amount-pos {
                    opacity: 1;
                }

                @for $i from 1 through $number-of-options {
                    &:nth-child(#{$i * 2 - 1}) ~ #debt-amount-pos {
                        left: #{($i * 16.666666667%) - 8.3%};
                    }
                }
            }
        }

        #debt-amount-pos {
            display: block;
            position: absolute;
            top: 50%;
            width: 12px;
            height: 12px;
            background: #3597fa;
            border-radius: 50%;
            transition: all 0.15s ease-in-out;
            transform: translate(-50%, -50%);
            border: 1px solid #fff;
            opacity: 0;
            z-index: 2;
        }
    }

    &:valid {
        #debt-amount-slider {
            input {
                + label::before {
                    transform: translate(-50%, 45px) scale(0.9);
                    transition: all 0.15s linear;
                }

                &:checked + label::before {
                    transform: translate(-50%, 45px) scale(1.1);
                    transition: all 0.15s linear;
                }
            }
        }
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
        width: 24px;
        opacity: 1;
        margin-right: 12px;
    }
    to {
        transform: rotate(360deg);
        width: 24px;
        opacity: 1;
        margin-right: 12px;
    }
}

.exchanger {
    position: relative;
    display: flex;
    flex-direction: column;
}

.step-wrap {
    width: 100%;
}

.step {
    background-color: $white;
    border-radius: $border-sm;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.32);
    transition: opacity 0.2s;
}

.step .checkbox {
    margin: 24px 16px 0 16px;
}

.step .button {
    width: calc(100% - 32px);
    margin: 32px 16px 16px 16px;
}

.step-header {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 8px 16px;
}

.step-name {
    font-size: 24px;
    color: $grafit;
}

.step-sub-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: $step-sub-header;
    font-size: 14px;
    font-weight: 500;
    padding: 0 16px 8px 16px;
}

.step-sub-header .reserves {
    color: $grey-lg-text;
    font-weight: 400;
}

.step-details {
    transition: opacity 0.2s;
}

.step-sub-header.inputs {
    padding: 24px 16px 0 16px;
}

.step-details .input-wrapper {
    margin: 16px;
}

.step-details .step-header {
    border-bottom: 1.2px solid rgba(0, 0, 0, 0.12);
}

.step-details .currency {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.step-details .currency.take {
    padding-top: 32px;
}

.step-details .currency .image {
    object-fit: cover;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    margin-right: 16px;
}

.step-details .currency .details {
    padding-top: 4px;
}

.step-details .currency .details .amount {
    color: $cur-name;
    font-size: 18px;
    font-weight: 500;
    display: flex;
}

.step-details .currency .details .amount .code {
    font-weight: 400;
    margin-left: 0.5rem;
}

.step-details .currency .details .name {
    color: $cur-name;
    font-size: 16px;
    margin-bottom: 0.5rem;
    display: flex;
    // align-items: center;
}

.step-details .icon-exchange {
    color: $cur-name;
    padding-left: 4px;
    margin-bottom: 4px;
}

.step-details .rate-with-commission {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: $cur-name;
    padding: 24px 0 8px 0;
}

.step-details .rate-with-commission .commission .code {
    color: $code;
}

.step-details .rate-with-commission .label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #4f4f4f;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 12px;
}

.step-details .rate-with-commission > .rate,
.step-details .rate-with-commission .commission {
    font-weight: 500;
}

.step-details .rate-with-commission > .rate .code,
.step-details .rate-with-commission .commission .code {
    font-weight: 400;
    color: $code;
}

.code {
    color: $code;
}

.step-details .rate-with-commission > .rate i {
    margin: 0 4px;
    color: $grafit;
}

.step-details .notification {
    color: #20459b;
    font-size: 14px;
    line-height: 22px;
    padding: 4px 16px 0 16px;
    margin-bottom: -4px;
}

.notification.night {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
}

.currencyFilter {
    display: flex;
    padding: 8px 16px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin-bottom: 1rem;
}

.filterItem {
    padding: 0.2rem 0.7rem;
    color: $grafit;
    border-radius: $border-norm;
    border: 1.4px solid #cccccc;
    margin-right: 0.4rem;
    position: relative;
    background-color: $white;
    line-height: 1.4;
    cursor: pointer;
    -webkit-transition: border-color 0.2s ease, box-shadow 0.2s ease, -webkit-transform 0.2s ease;
    transition: transform 0.2s ease, border-color 0.2s ease, box-shadow 0.2s ease, -webkit-transform 0.2s ease;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover {
        box-shadow: 0 1px 4px rgba(95, 95, 95, 0.53);
        -webkit-transform: translateY(-2px);
        transform: translateY(-2px);
        cursor: pointer;
    }

    &.active {
        box-shadow: 0 1px 4px rgba(95, 95, 95, 0.53);
        -webkit-transform: translateY(-2px);
        transform: translateY(-2px);
        border: 1.5px solid $orange;
    }
}

.qiwi-county {
    display: flex;
    align-items: center;
    align-self: center;
    height: 50px;
    margin-top: 1rem;
}

.flag {
    width: 30px;
    height: 30px;
    margin-left: 16px;
}

.qiwi-input {
    width: 100%;
    margin-left: 10px !important;
}
.checkbox {
    display: block;
    position: relative;
    // padding-left: 36px;
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    color: #646464;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    justify-content: space-between;
}

.checkbox img {
    transform: rotate(90deg);
    width: 20px;
    transition: transform 0.5s;
}

.checkbox:hover {
    img {
        opacity: 0.8;
    }
}

.checkbox.activeCupon img {
    transform: rotate(-90deg);
}
.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #ebeff6;
    border-radius: 2px;
}
.checkbox input:checked ~ .checkmark {
    background-color: #3597fa;
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.checkbox input:checked ~ .checkmark:after {
    display: block;
}
.checkbox .checkmark:after {
    left: 7px;
    top: 4px;
    width: 6px;
    height: 9px;
    border: solid #ffffff;
    border-width: 0 1.6px 1.6px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.checkbox .regulations {
    text-decoration: underline;
}
.checkbox .regulations:hover {
    color: #3597fa;
}
@media (max-width: 739px) {
    .technical-break {
        padding: 32px 16px 16px 16px;
    }
    .technical-break > .image {
        max-width: 180px;
    }
    .step-wrap {
        padding: 4px;
    }
    .step {
        width: calc(100% - 8px) !important;
        margin: 4px !important;
    }
    .step-name {
        margin: 0;
    }
}

.cupon {
    overflow: hidden;
    height: 0px;
    transition: height 0.5s;
}

.showCupon {
    height: 42px;
}

.city-section {
    margin-top: 20px;
    display: flex;
    width: 100%;
    align-items: center;
    color: #565656;
}

.city-section_name {
    margin-right: 5px;
}

.city-section_value {
    font-weight: 600;
}

.promocode-error {
    color: red;
    margin: 16px;
    font-size: 14px;
}

.promocode-discount-text {
    color: #565656;
    font-size: 14px;
    margin-top: 10px;
}

.step-details.blured-section,
.step.blured-section {
    opacity: 0.7;
    pointer-events: none;
}

.captcha {
    margin: 24px 16px;
}

.select_net_div {
    margin-bottom: 10px;
}

.select_net_div-title {
    color: $step-sub-header;
    font-size: 15px;
    font-weight: 500;
    padding: 0 16px 6px 16px;
}

.select_net_div-list {
    padding: 0px 16px;
    display: flex;
    width: max-content;
    justify-content: flex-start;
    border-radius: $border-norm;
    // margin-bottom: 1rem;
    background-color: $white;
    cursor: pointer;
    position: relative;
}

.select_net_div-list_item {
    opacity: 0.5;
    background-color: $white;
    border: 1.5px solid #cccccc;
    color: $grafit;
    padding: 0.1rem 0.5rem;
    position: relative;
    line-height: 1.4;
    -webkit-transition: border-color 0.2s ease, box-shadow 0.2s ease, -webkit-transform 0.2s ease;
    transition: transform 0.2s ease, border-color 0.2s ease, box-shadow 0.2s ease, -webkit-transform 0.2s ease;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover {
        opacity: 1;
        box-shadow: 0 1px 4px rgba(95, 95, 95, 0.53);
        -webkit-transform: translateY(-2px);
        transform: translateY(-2px);
        cursor: pointer;
    }

    &.active {
        opacity: 1;

        box-shadow: 0 1px 4px rgba(95, 95, 95, 0.53);
        border: 1.5px solid $orange;
    }
}

.select_net_div-list_item:first-child {
    border-top-left-radius: $border-norm;
    border-bottom-left-radius: $border-norm;
}
.select_net_div-list_item:last-child {
    border-top-right-radius: $border-norm;
    border-bottom-right-radius: $border-norm;
}
</style>
