<template lang="">
    <div>
        <div v-if="image" class="upload-receipt_image-wrap-layout">
            <div class="upload-receipt_image-wrap">
                <img v-if="!isPdf" alt="" width="100%" :class="{ 'upload-receipt_image-content': true, uploading: uploading }" :src="image" />
                <div class="upload-receipt_image-remove" @click="removeFile" :title="$t('file.removeFileTitle')">
                    <Icon color="#e84c4c" icon="carbon:close-outline" height="30" />
                </div>
                <div v-if="uploading" class="upload-receipt_uploading-icon">
                    <Icon icon="iconamoon:cloud-upload-light" height="80" color="#8cc3fa" />
                </div>
                <div v-if="isPdf" class="upload-receipt_filename-wrap">
                    <p class="upload-receipt_filename-text">{{ filename }}</p>
                </div>
            </div>
        </div>
        <div v-if="!image" class="upload-receipt_upload-btn-wrap">
            <input type="file" id="add-image" accept="image/jpeg,image/png,image/gif,application/pdf" @change="changeImage" />
            <label for="add-image">
                {{ $t("file.addText") + " PNG/JPG/JPEG/PDF" }}
            </label>
        </div>
    </div>
</template>
<script>
import { mapMutations } from "vuex";
// Icons
import { Icon } from "@iconify/vue2";
import axios from "axios";
export default {
    data() {
        return {
            image: null,
            file: null,
            uploading: false,
            isPdf: false,
            filename: ""
        };
    },
    props: {
        setHandler: Function,
        clearHandler: Function
    },
    computed: {},
    components: {
        Icon
    },
    methods: {
        ...mapMutations(["SET_SNACK_BAR"]),
        changeImage(e) {
            let than = this;
            const files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                return;
            }
            let file = files[0];
            let size = file.size;
            let fileName = file.name;
            let fileExtension = fileName.split(".").pop();
            let extensions = ["png", "jpg", "jpeg", "pdf"];
            if (!extensions.includes(fileExtension)) {
                this.SET_SNACK_BAR({ active: true, text: "Ошибка расширения!" });
            }
            const MAX_SIZE = 5e6;
            // проверка размера
            if (size <= MAX_SIZE) {
                checkImage(file);
                if (fileExtension == "pdf") {
                    this.filename = fileName;
                    this.isPdf = true;
                }
            } else {
                this.SET_SNACK_BAR({ active: true, text: "Максимальный размер файла 5 МБ." });
            }
            function checkImage(file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    than.setFile(file);
                    createImage(file);
                };
                reader.readAsArrayBuffer(file);
            }
            function createImage(file) {
                const reader = new FileReader();
                reader.onload = e => {
                    than.setImage(e.target.result);
                };
                reader.readAsDataURL(file);
            }
        },
        removeFile() {
            this.file = null;
            this.image = null;
            this.isPdf = false;
            this.filename = "";
            this.clearHandler();
        },
        setImage(i) {
            this.image = i;
        },
        setFile(f) {
            this.file = f;
            this.uploadFileHandler();
        },
        uploadFileHandler() {
            if (!this.file) return;
            if (this.uploading) return;
            this.uploading = true;
            let form = new FormData();
            form.append("file", this.file);
            axios
                .post("/file/upload", form)
                .then(({ data }) => {
                    let filename = data.filename;
                    this.setHandler(filename);
                })
                .catch(e => {
                    let message = e?.response?.data?.message;
                    if (message) {
                        this.SET_SNACK_BAR({ active: true, text: message });
                    }
                })
                .finally(() => {
                    this.uploading = false;
                });
        }
    }
};
</script>
<style lang="scss" scoped>
.upload-receipt_image-wrap-layout {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.upload-receipt_image-wrap {
    width: 50%;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}
@media (max-width: 600px) {
    .upload-receipt_image-wrap {
        width: 100%;
    }
}
.upload-receipt_image-content {
    width: 100%;
    border: 2px solid $grafit;
    border-radius: 10px;
    overflow: hidden;
}
.upload-receipt_image-content.uploading {
    opacity: 0.2;
}
.upload-receipt_image-remove {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
.upload-receipt_uploading-icon {
    position: absolute;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
}
.upload-receipt_upload-btn-wrap {
    margin: 16px 0;
    display: flex;
    justify-content: center;
}
.upload-receipt_upload-btn-wrap #add-image {
    display: none;
}
.upload-receipt_upload-btn-wrap #add-image + label {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: $grafit;
    padding: 10px 16px 14px 16px;
    border: 1px solid $orange;
    font-size: 14px;
    border-radius: 8px;
    width: max-content;
    min-width: 50%;
    text-align: center;
}
.upload-receipt_upload-btn-wrap #add-image:focus + label,
#add-image + label:hover {
    color: $orange;
}
.upload-receipt_filename-wrap {
    min-height: 30px;
}
.upload-receipt_filename-text {
    text-align: center;
    margin-top: 20px;
}
</style>