<template>
    <div class="currency" :class="{ selected: isSelected || isSelectedNet }" @click="selectCurrency">
        <img alt="" class="image" :src="image" />
        <div class="name">{{ getCurrencyName(currency) }}</div>

        <div class="spacer" v-if="isReserve"></div>
        <div class="reserve" v-if="isReserve">
            {{ currency.reserve }} <span class="code">{{ currency.code }}</span>
        </div>
    </div>
</template>

<script>
// Utils
import transformNetName from "../../utils/transformNetName";

export default {
    props: {
        currency: {
            type: Object,
            default: () => ({})
        },
        selected: {
            type: Object,
            default: () => ({})
        },
        isReserve: {
            type: Boolean,
            default: false
        },
        nets: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        isSelected() {
            return this.currency._id === this.selected._id;
        },
        image() {
            return `${this.URL.server}/images/${this.currency.image}.png`;
        },
        isSelectedNet() {
            if (this.nets && this.nets.length && this.selected) {
                let isCurrencyExistNet = this.nets.some(c => c._id == this.currency._id);
                return isCurrencyExistNet;
            } else {
                return false;
            }
        }
    },
    methods: {
        transformNetName: transformNetName,
        selectCurrency() {
            if (!this.isSelected) {
                this.$emit("currency-selected", this.currency);
            }
        },
        getCurrencyName(currency) {
            let name = currency.name;

            if (currency.crypto && currency.isMultinet) {
                return name + ` ${this.transformNetName(currency.netCode)}`;
            } else {
                return name;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.currency {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding: 16px 16px 8px 20px;
    margin: 4px 0;
}

.currency:hover {
    background-color: $cur--hover;
}

.currency.selected {
    cursor: auto;
    background-color: $cur--select;
}

.currency.selected:after {
    height: 100%;
    width: 2.4px;
    display: block;
    content: " ";
    bottom: 0;
    left: 0;
    position: absolute;
    background-color: $orange;
}

.currency:last-child {
    margin: 8px 0 16px 0;
}

.currency .image {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-right: 16px;
    -o-object-fit: cover;
    object-fit: cover;
}

.currency .name {
    color: $cur-name;
}

.currency .reserve {
    color: $cur-name;
    font-size: 14px;
    white-space: nowrap;
}

.currency .reserve .code {
    color: $code;
}
</style>
